import classNames from 'classnames'
import { twFormat } from '../../../utils/client/twFormat'

export type ButtonSize = 'lg' | 'sm' | 'normal' | 'custom'
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-outline'
  | 'primary-outline'
  | 'neutral'
  | 'neutral-outline'
  | 'red'
  | 'green'
  | 'link'

export type ButtonProps = {
  variant?: ButtonVariant
  size?: ButtonSize
}

export const buttonSizeOptions: ButtonSize[] = ['sm', 'normal', 'lg', 'custom']
export const buttonVariantOptions: ButtonVariant[] = [
  'primary',
  'primary-outline',
  'secondary',
  'secondary-outline',
  'neutral',
  'neutral-outline',
  'red',
  'green',
  'link',
]
const sizeClassNames: Record<ButtonSize, string> = {
  lg: twFormat('px-6 py-3 text-lg'),
  normal: twFormat('px-6 py-2 text-base'),
  sm: twFormat('px-3 py-1 text-sm'),
  custom: '',
}
const variantClassNames: Record<ButtonVariant, string> = {
  primary: twFormat(
    'border-blue-600 bg-blue-600 text-white ring-blue-400 ring-offset-1 ring-offset-transparent hover:bg-blue-500 hover:ring-1 focus:ring-1',
  ),
  'primary-outline': twFormat(
    'border-blue-600 bg-transparent text-blue-600 hover:border-blue-50 hover:bg-blue-50 hover:text-blue-600 focus:ring-2 focus:ring-blue-900',
  ),
  secondary: twFormat(
    'border-blue-800 bg-blue-800 text-white ring-gray-200 ring-offset-1 ring-offset-transparent hover:bg-blue-700 hover:ring-1 focus:ring-1',
  ),
  'secondary-outline': twFormat(
    'border-blue-700 bg-transparent text-blue-700 hover:border-blue-100 hover:bg-blue-100 hover:text-blue-700 focus:ring-2 focus:ring-blue-900',
  ),
  neutral: twFormat(
    'border-gray-100 bg-gray-100 text-font-dark hover:bg-gray-300 hover:text-font-dark focus:ring-2 focus:ring-gray-300 focus:ring-offset-2',
  ),
  'neutral-outline': twFormat(
    'border-gray-300 bg-transparent text-gray-300 hover:border-gray-100 hover:bg-gray-100 hover:text-font-dark focus:ring-2 focus:ring-gray-300',
  ),
  red: twFormat(
    'border-red-500 bg-red-500 text-white hover:bg-red-700 hover:text-white focus:ring-2 focus:ring-red-700 focus:ring-offset-2',
  ),
  green: twFormat(
    'border-green-500 bg-green-500 text-white hover:bg-green-700 hover:text-white focus:ring-2 focus:ring-green-700 focus:ring-offset-2',
  ),
  link: twFormat(
    'border-none bg-transparent text-link hover:text-link hover:underline focus:underline',
  ),
}
const baseStyles = twFormat(
  'inline-flex items-center rounded-lg border text-center focus:outline-none',
)

export const getCombinedButtonStyles = ({
  size,
  variant,
}: {
  size: ButtonSize
  variant: ButtonVariant
}) => {
  return classNames(baseStyles, variantClassNames[variant], sizeClassNames[size])
}
