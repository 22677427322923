import classNames from 'classnames'
import React from 'react'

export const PageHeaderSection = React.forwardRef<HTMLElement, React.HTMLAttributes<HTMLElement>>(
  ({ className, children, ...props }, ref) => (
    <section ref={ref} className={classNames('mx-auto my-7 md:my-12', className)} {...props}>
      {children}
    </section>
  ),
)
PageHeaderSection.displayName = 'PageHeaderSection'
