'use client'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import { Footer } from '../components/core/Footer'
import { FullHeight } from '../components/core/FullHeight'
import { ExternalLink, InternalLink } from '../components/core/Link'
import { PageH1 } from '../components/core/PageH1'
import { PageH2 } from '../components/core/PageH2'
import { PageHeaderSection } from '../components/core/PageHeaderSection'
import { PageSpacing } from '../components/core/PageSpacing'
import { urls } from '../urls'

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    Sentry.captureException(error, { tags: { domain: 'globalError' } })
    Sentry.captureMessage('Global Error Page Displayed')
  }, [])
  return (
    <html lang="en">
      <body>
        <FullHeight.Container
          style={{ backgroundImage: `url(/wave.svg)` }}
          className="bg-no-repeat"
        >
          <FullHeight.Content>
            <aside className="bg-blue-200 p-4 text-center" role="alert">
              <p className="text-blue-900">
                Want to get realtime updates when influential people talk about crypto? Follow{' '}
                <ExternalLink
                  className="font-bold text-blue-800 underline"
                  href="https://twitter.com/DoTheySupportIt"
                >
                  @DoTheySupportIt
                </ExternalLink>
                .
              </p>
            </aside>
            <PageSpacing className="pt-4">
              <PageHeaderSection>
                <PageH1>Whoops, something went wrong!</PageH1>
                <PageH2>
                  Looks like we encountered an error. The team has been notified and we're actively
                  working on a solution, sorry about that! Please go back to{' '}
                  <InternalLink href={urls.homepage()}>the homepage</InternalLink>...or stay and
                  watching this dancing gif of Elon Musk.
                </PageH2>
              </PageHeaderSection>
              <div className="mx-auto text-center">
                {/* eslint-disable-next-line */}
                <img
                  className="mx-auto"
                  src={'/404.gif'}
                  alt="Elon Musk dancing as Wario....because why not"
                />
              </div>
            </PageSpacing>
          </FullHeight.Content>
          <Footer />
        </FullHeight.Container>
      </body>
    </html>
  )
}
