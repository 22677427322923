import { requiredEnv } from './utils/server/requiredEnv'

export type ENVIRONMENT = 'local' | 'testing' | 'production'

declare global {
  interface Window {
    NEXT_PUBLIC_APP_ENVIRONMENT: ENVIRONMENT
  }
}

interface IAppConfig {
  ENVIRONMENT: ENVIRONMENT
  ORIGIN: string
}

const getAppConfig = (): IAppConfig => {
  const ENVIRONMENT = requiredEnv(
    'NEXT_PUBLIC_APP_ENVIRONMENT',
    process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
  ) as ENVIRONMENT

  switch (ENVIRONMENT) {
    case 'local':
      return {
        ENVIRONMENT,
        ORIGIN: 'localhost:3000',
      }
    case 'testing':
      return {
        ENVIRONMENT,
        ORIGIN: 'https://testing.dotheysupportit.com',
      }
  }
  return {
    ENVIRONMENT,
    ORIGIN: 'https://www.dotheysupportit.com',
  }
}

export const APP_CONFIG = getAppConfig()
