import classNames from 'classnames'
import NextBaseLink, { LinkProps } from 'next/link'
import React from 'react'

import { splitNextLinkProps } from '../../utils/splitNextLinkProps'
import { ButtonProps, getCombinedButtonStyles } from './buttonUtils'

const ButtonLinkBase = React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonProps
>(({ className, variant = 'primary', size = 'normal', ...props }, ref) => (
  <a
    ref={ref}
    className={classNames(getCombinedButtonStyles({ variant, size }), 'hover:underline', className)}
    {...props}
  />
))
ButtonLinkBase.displayName = 'ButtonLinkBase'

export const ButtonLink = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonProps
>((props, ref) => {
  const { nextProps, rest } = splitNextLinkProps(props)

  return (
    <NextBaseLink {...nextProps} passHref legacyBehavior>
      <ButtonLinkBase {...rest} ref={ref} />
    </NextBaseLink>
  )
})
ButtonLink.displayName = 'ButtonLink'

export const ExternalButtonLink = React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonProps & { linkLabel?: string }
>(({ linkLabel, ...props }, ref) => <ButtonLinkBase {...props} ref={ref} />)
ExternalButtonLink.displayName = 'ExternalButtonLink'
