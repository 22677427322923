import classNames from 'classnames'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import React from 'react'
import { splitNextLinkProps } from '../utils/splitNextLinkProps'

export const InternalLink = React.forwardRef<
  HTMLAnchorElement,
  NextLinkProps & React.LinkHTMLAttributes<HTMLAnchorElement>
>((props, ref) => {
  const {
    nextProps,
    rest: { className, ...rest },
  } = splitNextLinkProps(props)
  return (
    <NextLink legacyBehavior passHref {...nextProps}>
      <a className={classNames('hover:underline', className)} ref={ref} {...rest} />
    </NextLink>
  )
})
InternalLink.displayName = 'InternalLink'

export const ExternalLink = React.forwardRef<
  HTMLAnchorElement,
  React.LinkHTMLAttributes<HTMLAnchorElement>
>(({ className, ...props }, ref) => {
  return (
    <a target="_blank" className={classNames('hover:underline', className)} ref={ref} {...props} />
  )
})
ExternalLink.displayName = 'ExternalLink'
