import { LinkProps } from 'next/link'

export const splitNextLinkProps = <T extends LinkProps>(props: T) => {
  const { href, replace, scroll, shallow, passHref, prefetch, locale, ...rest } = props
  const nextProps: LinkProps = {
    href,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
    locale,
  }
  return { nextProps, rest }
}
