import classNames from 'classnames'
import React from 'react'
import { cn } from '../../utils/client/cn'

export const PageH2 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h2
    ref={ref}
    className={cn('text-font-muted mt-4 text-center text-lg md:text-xl', className)}
    {...props}
  >
    {children}
  </h2>
))
PageH2.displayName = 'PageH2'
