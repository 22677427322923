import { APP_CONFIG } from './appConfig'
import { CURRENT_US_CONGRESS_GROUP_INSTANCE } from './constants'

export const personCryptoDetailsInitialPositionId = ({
  type,
  id,
}: {
  type: 'analysis' | 'stance'
  id: string
}) => `${type}-${id}`

const includeCongressGroupInstance = (groupInstance?: string): groupInstance is string => {
  return !!groupInstance && groupInstance !== CURRENT_US_CONGRESS_GROUP_INSTANCE
}

export const urls = {
  homepage: () => '/',
  termsAndConditions: () => '/terms-and-conditions',
  privacyPolicy: () => '/privacy-policy',
  userHomepage: () => '/user',
  stances: () => '/recent-stances',
  upsertStanceAnalysis: (stanceId: string) => `/stances/${stanceId}/create-analysis`,
  updateStance: (stanceId: string) => {
    return `/stances/${stanceId}/update`
  },
  createStance: (slug: string) => `/people/${slug}/create-stance`,
  faq: () => '/faq',
  billDetails: (id: string) => `/bills/${id}`,
  upsertBillAnalysis: (id: string) => `/bills/${id}/create-analysis`,
  groupUSCongressBills: () => `/bills/us-congress`,
  groupUSSenators: (groupInstance?: string) =>
    `/groups/us-senators/crypto${
      includeCongressGroupInstance(groupInstance) ? `/session/${groupInstance}` : ''
    }`,
  groupUSSenatorsCommittees: (groupInstance?: string) =>
    `/groups/us-senators/crypto/committees${
      includeCongressGroupInstance(groupInstance) ? `/${groupInstance}` : ''
    }`,
  groupUSRepresentativesCommittees: (groupInstance?: string) =>
    `/groups/us-representatives/crypto/committees${
      includeCongressGroupInstance(groupInstance) ? `/${groupInstance}` : ''
    }`,
  groupUSRepresentatives: (groupInstance?: string) =>
    `/groups/us-representatives/crypto${
      includeCongressGroupInstance(groupInstance) ? `/session/${groupInstance}` : ''
    }`,
  personCryptoDetails: (
    slug: string,
    { initialPosition }: { initialPosition?: { type: 'analysis' | 'stance'; id: string } } = {},
  ) =>
    `/people/${slug}/crypto${
      initialPosition ? `#${personCryptoDetailsInitialPositionId(initialPosition)}` : ''
    }`,
}

export const fullUrl = (path: string) => `${APP_CONFIG.ORIGIN}${path}`
