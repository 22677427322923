import classNames from 'classnames'
import React from 'react'

export const PageSpacing = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { vertical?: boolean }
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={classNames('mx-auto w-full max-w-7xl px-4', className)} {...props}>
    {children}
  </div>
))
PageSpacing.displayName = 'PageSpacing'
