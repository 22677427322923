import classNames from 'classnames'
import React from 'react'
import styles from './LinkBox.module.css'

export const LinkBox = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div className={classNames(styles.base, className)} {...props} ref={ref} />
  },
)
LinkBox.displayName = 'LinkBox'
export const linkBoxLinkClassName = styles.overlay
